<script>
export default {
  name: "ThankYou"
}
</script>

<template>
  <div class="container-thank-you">
    <div class="box">
      <h1>Thank You &check;</h1>
      <h2>Your request for a FREE QUOTE has been submitted!<br/><br/> A representative will reach out to you in the next 24 hours.</h2>
    </div>
  </div>
</template>